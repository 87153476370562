import Body from '../components/Body';



export default function ExplorePage() {
  return (
    <Body>
        <h1>Explore</h1>
        <p>TODO</p>  
    </Body>
  );
}