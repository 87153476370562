import Body from '../components/Body';
import About from '../components/About';
import Posts from '../components/Posts';


export default function AboutPage() {
    return (
    <Body>
        <Posts />
    </Body>
  );
}