import Body from '../components/Body';
import Container from 'react-bootstrap/Container';

export default function InfoPage() {
  return (
    <Body>
      
      <h1>More Info</h1>
      <p>TODO</p>
     
      
    </Body>
  );
}